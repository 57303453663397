@import "fonts";
@import "libs";
@import "vars";
@import "reset";


* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }

html, body {
	height: 100%; }

body {
	font-size: 16px;
	min-width: 320px;
	position: relative;
	line-height: 1.4;
	font-family: $osr;
	overflow-x: hidden;
	color: #000; }
img {
	max-width: 100%; }
.hidden {
	display: none; }

ul,
li {
	list-style-type: none;
	padding: 0;
	margin: 0; }
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box; }
.clearfix::after {
	content: "";
	display: table;
	clear: both; }
a {
	transition: all 0.3s;
	text-decoration: none;
	color: #000;
	&:hover {
		opacity: .8; } }
img,
input,
textarea {
	max-width: 100%; }
#thanks {
	.form-item {
		font-size: 18px;
		text-align: center; } }
.img-responsive {
	display: block;
	max-width: 100%;
	height: auto; }

.text-center {
	text-align: center; }
.s-wide {
 }	// стили для полной секции

.s-dark {
 }	// стили для темной секции
.wrap-items {
	display: flex;
	flex-wrap: wrap; }

.button, a.button, button {
	border: none;
	&:hover {
		opacity: .8; } }
.button-block {

	.button-form {
		color: #fff;
		border-width: 3px;
		border-style: solid;
		display: block;
		font-family: $rcb;
		font-size: 20px;
		line-height: 40px;
		width: 240px;
		margin: 0 auto;
		text-transform: uppercase;
		border-radius: 11px;
		text-shadow: 1px 1px #2F2F2F;
		&.button-blue {
			background: linear-gradient(to top, #0FAFEC 0%,#0093CB 100%); } }
	.button {
		border-bottom: 3px solid #fff;
		border-radius: 40px;
		font-family: $rcb;
		font-size: 27px;
		color: #000;
		text-transform: uppercase;
		text-align: center;
		display: table;
		margin: 0 auto;
		padding: 14px 30px;
		position: relative;
		background-color: #fff;
		font-weight: bold;
		background-color: #FFC900;
		box-shadow: 0 3px 0px #C39C00;
		position: relative;
		cursor: pointer;
		&:before {
			content: "";
			position: absolute;
			width: 90%;
			height: 1px;
			border-bottom: 1px dashed #000;
			bottom: 15px;
			left: 5%; }
		&:hover {
			background: #413F3A;
			color: #fff;
			opacity: 1;
			box-shadow: 0 3px 0px #999;
			&:before {
				border-bottom: 1px dashed #fff; } }
		&-dark {
			border-bottom: 3px solid #fff;
			border-radius: 40px;
			font-family: $rcb;
			font-size: 18px;
			color: #fff;
			text-transform: uppercase;
			text-align: center;
			display: table;
			margin: 0 auto;
			padding: 14px 30px;
			position: relative;
			background-color: #fff;
			font-weight: bold;
			background-color: #413f3a;
			box-shadow: 0 3px 0px #C5C5C5;
			position: relative;
			&:hover {
				background: $or;
				color: #2B2306;
				opacity: 1;
				box-shadow: 0 3px 0px #C39C00; } } } }
.section {
	padding: 60px 0; }
h1, .h1 {
	font-family: Arial, sans-serif;
	font-size: 42px;
	font-weight: bold;
	padding: 12px 50px;
	text-align: center;
	box-shadow: 2px 4px 0px #C19900;
	background: $or;
	border-radius: 7px; }
h2, .h2 {
	font-family: $rcb;
	font-size: 30px;
	text-transform: uppercase;
	text-align: center;
	&.figure {
		font-family: $rcb;
		font-size: 27px;
		text-transform: uppercase;
		background: #fff;
		color: #413f3a;
		border-bottom: 3px solid $or;
		border-radius: 40px;
		padding: 14px 30px;
		display: table;
		margin: 0 auto 50px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 25px 58px 0 58px;
			border-color: #ffcc00 transparent transparent transparent;
			bottom: -26px;
			left: calc(50% - 58px); } } }

h3, .h3 {
	font-size: 24px;
	line-height: 1;
	font-family: $rcb;
	color: #413f3a;
	text-align: center; }


.top-header-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	nav {}
	ul {

		height: 37px;
		margin-top: 3px;
		li {
			float: left;
			font-size: 24px; }
		a {
			margin: 0px 10px 0 10px;
			padding: 0px 10px 0px 10px;
			background: $or;
			transition: 0.4s;
			border-radius: 10px;
			position: relative;
			&:hover {
				border-top: solid 3px #000; } } } }

.top-header {
	font-family: $rcb;
	background: #fff;
	box-shadow: 0 0px 13px #D7D7D7;
	padding: 0 0 10px;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 9999;
	.img-logo {
		text-transform: uppercase;
		font-size: 43px;
		line-height: 1; }
	.logo-text {
		background: $or;
		font-size: 15px;
		text-transform: uppercase;
		padding: 0 15px; } }
.s-header {
	background: url("../img/header-bg.jpg") 0 0;
	background-size: cover;
	padding-top: 130px;
	padding-bottom: 25px;
	h1 {
		display: block;
		margin: 0 25px; }
	.descr {
		background: #FDD72C;
		font-family: Arial,sans-serif;
		font-size: 23px;
		font-weight: bold;
		display: inline-block;
		position: absolute;
		top: 70px;
		right: 25px;
		transform: rotate(-3deg);
		padding: 15px 40px;
		border-radius: 10px; }
	.h1-block {
		position: relative;
		z-index: 5; }
	.wrap-blocks {
		display: flex;
		justify-content: space-between; }
	.wrap-action {
		position: relative; }
	.action-block {
		width: 335px;
		background: rgba(28, 28, 28, 0.76);
		text-align: center;
		position: relative;
		margin-top: -100px;
		border-radius: 20px;
		padding-top: 120px;
		padding-bottom: 20px;
		color: #fff;
		font-size: 40px;
		font-family: $a;
		font-weight: bold;
		text-shadow: 0px 4px 10px #000;
		&:before {
			@include before();
			background-image: url("../img/uzor.png");
			top: 0;
			left: 0; }

		h3 {
			color: $or;
			text-transform: uppercase;
			line-height: 1; }
		.num-block {
			font-size: 100px;
			color: $or; }
		.if {
			font-size: 25px;
			color: $or;
			margin-bottom: 15px; } }
	.action-block-2 {
		min-width: 330px;
		max-width: 330px;
		border-radius: 10px;
		background: rgba(251, 247, 247, 0.89);
		font-size: 14px;
		font-family: $a;
		padding: 0 25px 20px;
		margin-top: 65px;
		position: relative;
		.title {
			font-size: 23px;
			font-weight: bold;
			background: url("../img/calcul_zag.png") no-repeat;
			width: 337px;
			height: 100px;
			margin-left: -15px;
			text-align: center;
			font-weight: bold;
			padding-top: 8px;
			position: absolute;
			top: 0;
			left: -25px;
			p {
				transform: rotate(-3deg); } }
		.content-block {
			padding-top: 100px;
			width: 230px;
			margin: auto;
			text-align: center; }
		label {
			span {
				font-weight: bold;
				margin-bottom: 10px; } }
		.button-block {
			p {
				font-size: 18px;
				margin: 15px 0; } } }
	input {
		width: 100%;
		height: 41px;
		font-size: 24px;
		text-align: center;
		border-radius: 10px;
		margin-top: 10px;
		margin-bottom: 24px;
		background: #fff;
		border-top: 3px solid #ddd; }
	.people-block {
		position: absolute;
		top: 216px;
		right: -335px;
		z-index: 9;
		img {
			width: 377px;
			max-width: 377px; } }
	.sale {
		p {
			color: $or; } } }

.s-advantages {
	background: url("../img/advantages-bg.jpg") 50% no-repeat;
	text-align: center;
	padding: 87px 0 47px;
	min-height: 580px;
	.content-block {
		text-transform: uppercase;
		color: $or;
		font-family: $rcb;
		font-size: 24px;
		line-height: 1;
		margin: 25px 0;
		span {
			display: block;
			color: #000; } }
	.wrap-items {
		justify-content: space-between; } }
.s-readyGoods {
	margin-top: -180px;
	padding-bottom: 80px;
	.text-block {
		h3 {
			span {
				display: block; } } }
	.wrap-item {
		display: flex;
		justify-content: space-between;
		background: #F4F4F4; }
	.img-block {
		border: 14px solid #fff;
		margin: -77px 0 0 39px;
		position: relative;
		box-shadow: 0px 2px 6px #9E9E9E;
		border-radius: 10px; }
	.text-block {
		width: 480px;
		margin-top: -80px; }
	h2 {
		margin-bottom: -10px; }
	.complect {
		flex-basis: 290px;
		border-bottom: 1px solid #BBBBBB;
		line-height: 1;
		display: flex;
		align-items: center;
		padding: 5px 0 5px 18px;
		background: #fff; }
	.complect-price {
		flex-grow: 1;
		background: $gr;
		color: #fff;
		justify-content: center;
		border-bottom: 1px solid #fff;
		display: flex;
		align-items: center; }
	.item-table {
		display: flex;
		font-family: $osb;
		font-size: 18px;
		min-height: 35px; }
	.wrap-table {}

	.table-block {
		box-shadow: 0px 2px 6px #9E9E9E;
		border-radius: 20px;
		overflow: hidden;
		margin-top: 20px; }
	.round-block {
		font-family: $osb;
		width: 133px;
		height: 133px;
		border-radius: 50%;
		background: rgb( 52, 50, 45 );
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		text-align: center;
		position: absolute;
		left: -30px;
		bottom: -45px; }
	.area {
		font-size: 26px;
		color: #fff;
		flex-basis: 100%;
		margin-top: 15px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			width: 85px;
			height: 1px;
			background: $or;
			bottom: -7px;
			left: calc(50% - 42.5px); } }
	.price {
		color: $or;
		font-size: 24px;
		flex-basis: 100%;
		margin-bottom: 25px; }
	.item {
		margin-top: 160px; }
	.angle-block {
		border-style: solid;
		border-width: 127px 570px 0 570px;
		border-color: rgb(244, 244, 244) transparent transparent;
		width: 0;
		height: 0; }
	.slick-next {
		top: 25%;
		right: -40px;
		&:before {
			color: $grey;
			&:hover {
				&:before {
					color: $gr; } } } }
	.slick-prev {
		top: 25%;
		left: -40px;
		&:before {
			color: $grey;
			&:hover {
				&:before {
					color: $gr; } } } }
	.second-arr, .second-arr-l {
		top: 75%; } }

.bg-block {
	background: url("../img/bg_repeat.png");
	height: 36px; }
.big-section {
	background: url("../img/fon-big-sec.jpg") no-repeat 50%;
	min-height: 2050px; }

.s-big-1 {
	padding: 50px 0 0;
	color: #34322d;
	font-family: $osr;
	.wrap-items {
		flex-wrap: nowrap;
		justify-content: space-between;
		max-width: 980px;
		margin: auto; }
	h3 {
		color: #343223;
		font-size: 30px;
		text-transform: uppercase;
		text-align: left;
		margin-bottom: 20px; }
	.img-block {
		text-align: center; }
	.wrap-img-block {
		height: 240px;
		width: 240px;
		border-radius: 50%;
		border: 7px solid #fff;
		overflow: hidden;
		img {
			max-width: 1000%;
			display: block; } }
	.text-block {
		flex-basis: 620px;
		font-size: 18px;
		line-height: 1.2; }
	.item {
		margin-bottom: 70px; } }

.s-scheme {
	text-align: center;
	.slick-next, .slick-prev {
		width: 70px;
		height: 70px;
		background: $grey;
		border-radius: 50%; }
	img {
		display: inline-block; }
	.img {
		text-align: center;
		max-width: 400px;
		margin: auto; }
	.slick-dots li button:before {
		font-size: 26px; }
	.slick-dots {
		bottom: -50px; }
	.text {
		margin: 25px auto 0;
		font-size: 24px;
		line-height: 1.2;
		font-family: $rcb;
		text-transform: uppercase;
		max-width: 300px; }
	.slick-prev {
		left: -70px; }
	.slick-next {
		right: -70px; }
	.button-block {
		margin-top: 70px; }
	.wrap-item {
		padding: 0 10px; } }

.s-dop {
	margin: 50px 0 0;
	.wrap-items {
		justify-content: space-between;
		margin-top: 100px; }
	.content-block {
		font-size: 24px;
		line-height: 1;
		text-transform: uppercase;
		color: #fff;
		font-family: $rcb;
		text-align: center;
		text-shadow: 0px 5px 10px #272727;
		margin-top: 30px;
		span {
			display: block; } } }
.s-galery {
	.slider-3 {
		margin: 0 -10px; }
	.item {
		margin: 0 10px; }
	.slick-next {
		height: 100%;
		width: 80px;
		background: rgba(#fff, .6);
		right: 10px;
		&:before {
			color: $grey;
			font-size: 100px; } }
	.slick-prev {
		height: 100%;
		width: 80px;
		background: rgba(#fff, .6);
		left: 10px;
		&:before {
			color: $grey;
			font-size: 100px; } }
	.slider-3 {
		margin: 80px 0; } }





.table-color {
	.table-block {
		box-shadow: 0px 2px 6px #9E9E9E;
		border-radius: 20px;
		overflow: hidden;
		margin-top: 20px;
		.complect {
			flex-basis: 290px;
			border-bottom: 1px solid #BBBBBB;
			line-height: 1;
			display: flex;
			align-items: center;
			padding: 5px 0 5px 18px;
			background: #fff; }
		.complect-price {
			flex-grow: 1;
			background: $gr;
			color: #fff;
			justify-content: center;
			border-bottom: 1px solid #fff;
			display: flex;
			align-items: center; }
		.item-table {
			display: flex;
			font-family: $osb;
			font-size: 18px;
			min-height: 35px; } } }

.s-prices {
	padding-bottom: 0;
	padding-top: 0;
	.table-color {
		width: 570px; }
	.complect {
		flex-basis: 410px;
		min-width: 410px; }
	.complect-price {
		flex-grow: 0; }
	.wrap-items {
		justify-content: space-around;
		align-items: center; } }

.s-review {
	.wrap-item {
		display: flex;
		align-items: center; }
	.img-block {
		min-width: 190px;
		width: 190px;
		height: 190px;
		border-radius: 50%;
		border: 4px solid $or;
		overflow: hidden;
		margin-right: 30px;
		img {
			width: 100%; } }
	.title {
		font-size: 22px;
		font-family: $osr;
		color: #2f2d29;
		margin-bottom: 15px; }
	.slick-dots li button:before {
		font-size: 15px; }
	.content-block {
		font-size: 16px;
		line-height: 1.5; } }

.s-manager {
	.wrap-items {
		justify-content: space-between;
		margin-top: 40px; }
	h2 {
		color: $or;
		font-family: $rcb;
		font-size: 56px;
		line-height: 1.1; }
	.descr {
		color: #413f3a;
		font-size: 38px;
		line-height: 1.15;
		font-family: $rcb;
		text-transform: uppercase;
		text-align: center; }
	.content-block {
		p {
			color: #2f2d29;
			font-family: $rcr;
			font-size: 18px;
			line-height: 1.5;
			text-align: center;
			margin: 12px 0 21px; }
		span {
			display: block; } }
	.img-block {

		overflow: hidden;
		img {
			border-radius: 50%;
			height: 182px;
			width: 182px; } }
	.button-block-but {
		margin-top: 80px;
		.button {
			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 25px 58px 0 58px;
				border-color: #fc0 transparent transparent transparent;
				top: -26px;
				left: calc(50% - 58px); } } } }

.free-bot {
	.section-wrap {
		border-top-left-radius: 40px;
		border-top-right-radius: 40px;
		background: rgb(216, 232, 210);
		font-family: $rcb;
		font-size: 30px;
		color: #5E7556;
		padding: 30px 0 20px;
		text-align: center; } }

footer {
	padding: 20px 0;
	background: #413f3a;
	color: #fff;
	font-family: $rcb;
	.logo {
		text-align: center;
		a {
			color: #fff;
			font-size: 36px;
			text-transform: uppercase; } }
	.logo-text {
		font-size: 16px;
		background: $or;
		padding: 0 30px;
		color: #000; }
	.section-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center; }
	a {
		color: #fff; }
	.contact-block {
		font-size: 18px; }
	.social-block {
		display: block !important;
		.social-item {
			display: inline-block;
			font-size: 40px;
			margin: 0 10px; } } }

#container-before-after {
	img {
		width: 100%;
		height: 480px; } }


.s-calc {
	padding: 80px 0;
	.figure {
		font-size: 27px; } }
.error {
	display: none;
	color: red; }
.wrap-quiz-items {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	input {
		height: 40px;
		width: 250px;
		border: 2px solid $green;
		text-indent: 5px;
		padding: 0 10px; }
	.js-progress {
		width: 350px;
		height: 10px;
		border-radius: 3px;
		border: 2px solid $or;
		margin-top: 2px; }
	.progress {
		margin-top: 40px; }
	.jspesent {
		display: block;
		height: 100%;
		background: darken($or, 10%); } }

.quiz-item:not(.quiz-item-1) {
	display: none;
	background: $or;
	.wrap-items {
		justify-content: space-around;
		margin: 0 0 auto; }
	.wrap-quiz:not(.quiz-item-1) {
		background: #fff;
		max-width: 760px;
		flex-basis: 760px;
		padding: 20px;
		border-radius: 25px;
		margin: auto;
		height: 500px;
		display: flex;
		flex-direction: column; }
	.prwv-h3 {
		margin-bottom: 30px;
		position: relative;
		padding-left: 30px;
		&:before {
			content: "";
			position: absolute;
			width: 250px;
			height: 2px;
			left: 0;
			bottom: -10px;
			background: $green; }
		&:after {
			content: "\2714";
			position: absolute;
			top: 2px;
			left: 0;
			width: 20px;
			height: 20px;
			border: 2px solid $green;
			text-align: center;
			line-height: 16px; } }
	h3 {
		font-size: 30px;
		margin: 25px 0; }
	.button-block {
		display: flex;
		justify-content: center;
		margin: 20px 0 30px; }
	.button {
		margin: 0 30px;
		font-size: 22px;
		min-width: 200px;
		padding: 10px 30px;
		position: relative;
		white-space: nowrap;
		&:before {
			width: 80%;
			left: 10%;
			bottom: 8px; }
		&.disabled {
			background: darken($or, 10%); } } }
.quiz-item-4 {
	.descr-item {
		margin-bottom: 5px; } }
.quiz-item-1 {
	background: url("../img/quiz-bg-1.jpg") no-repeat 50% 50%;
	background-size: cover;
	color: #eef8ff;
	text-align: center;
	h2 {
		margin-bottom: 20px;
		font-size: 50px; }
	.button-block {
		margin: 30px 0; }
	.descr {
		font-size: 24px;
		p {
			margin: 15px 0; } }
	.wrap-quiz {
		max-width: 930px;
		flex-basis: 930px; }
	.wrap-quiz {
		max-width: 930px;
		background: rgba(30, 30, 30, 0.5);
		padding: 20px;
		border-radius: 25px;
		margin: 50px auto 0; } }

.quiz-item-2 {
	background: $or;
	.wrap-quiz {
		background: #fff;
		h3 {
			margin-top: 15px; } }
	label {
		cursor: pointer;
		text-align: center;
		position: relative;
		display: block;
		&:hover {
			&:before {
				content: "";
				position: absolute;
				width: 20px;
				height: 20px;
				background: rgba(#000, .6);
				right: 10px;
				top: 10px;
				border-radius: 50%; } }
		&.active {
			&:after {
				content: "\2714";
				text-align: center;
				position: absolute;
				right: 10px;
				top: 10px;
				width: 20px;
				height: 20px;
				border: 2px solid $green;
				border-radius: 50%;
				color: #fff;
				line-height: 16px;
				font-size: 14px;
				box-shadow: 00 0 10px $green; } }

		input {
			display: none; } }
	.descr-item {
		margin: 15px 0; } }
.quiz-item-3, .quiz-item-5 {
	.wrap-items {
		display: block; }
	.wrap-quiz {
		background: #fff; }
	label {
		cursor: pointer;
		position: relative;
		display: block;
		&:before {
			content: "";
			position: absolute;
			left: 10px;
			top: 0px;
			width: 20px;
			height: 20px;
			border: 2px solid $green;
			border-radius: 50%; }
		&:hover {
			&:before {
				content: "";
				position: absolute;
				width: 20px;
				height: 20px;
				background: rgba(#000, .6);
				left: 10px;
				top: 0px;
				border-radius: 50%; } }
		&.active {
			&:after {
				content: "\2714";
				text-align: center;
				position: absolute;
				left: 10px;
				top: 0px;
				width: 20px;
				height: 20px;
				border: 2px solid $green;
				border-radius: 50%;
				color: #000;
				line-height: 16px;
				font-size: 14px;
				box-shadow: 00 0 10px $green; } }
		input {
			display: none; } }
	.descr-item {
		margin: 15px 0;
		padding-left: 50px; } }
.quiz-item-4 {
	.wrap-items {
		align-items: flex-end; }
	.wrap-quiz {
		h3 {
			margin: 15px 0; } }
	.item {
		flex-basis: 48%;
		margin-bottom: 15px; } }

.thank-page {
	height: calc(100vh - 140px);
	padding-top: 0;
	.descr-1 {
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		margin-top: 30px; }
	.h1-block {
		background: rgba(#fff, 0.5);
		padding: 50px 0; }
	.section-wrap {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 140px);
		justify-content: center; }
	.button-block {
		margin-top: 30px; } }
.than-page {
	.navigation-wrap {
		display: none; } }











.slick-next {
	width: 26px;
	height: 45px;
	z-index: 99;
	&::before {
		content: "\f105";
		font-family: FontAwesome;
		font-size: 70px; } }


.slick-prev {
	width: 26px;
	height: 45px;
	z-index: 99;
	&::before {
		content: "\f104";
		font-family: FontAwesome;
		font-size: 70px; } }

.slick-dots li.slick-active button:before {
	color: $or;
	opacity: 1; }
.slick-dots li button:before {
	color: $grey;
	opacity: 1; }



.mfp-bg {
	background: #eee; }
.mfp-close-btn-in .mfp-close {
	color: #fff; }

.form-popup, .form-dark {
	max-width: 330px;
	background: rgba(28, 28, 28, 0.76);
	color: #fff;
	padding: 40px 25px;
	border-radius: 20px;
	position: relative;
	margin: auto;
	.form-title {
		font-size: 30px;
		font-family: $rcb;
		text-align: center;
		margin-bottom: 25px; }
	input {
		width: 100%;
		height: 40px;
		border-radius: 5px;
		border: none;
		text-indent: 10px; }
	input[type=checkbox] {
		width: 16px;
		height: 16px; }
	label {
		margin-bottom: 10px;
		display: block; }
	.button-form {
		width: 100%;
		display: block;
		margin: 15px 0;
		background: $or;
		text-transform: none;
		color: #000;
		border-color: #fff;
		text-shadow: none; } }
.check-block {
	font-size: 10px;
	label {
		display: flex;
		align-items: center; }
	input {
		margin-right: 10px;
		min-width: 16px; } }

.close {
	display: none;
	font-size: 30px; }
.mobil-mnu {
	display: none; }
.mobil-mnu {
	background-color: #fff;
	color: #fff; }
.toggle-mnu {
	display: block;
	width: 28px;
	height: 28px;


	span:after, span:before {
		content: "";
		position: absolute;
		left: 0;
		top: 9px; }

	span:after {
		top: 18px; }

	span {
		position: relative;
		display: block; }

	span, span:after, span:before {
		width: 100%;
		height: 2px;
		background-color: #000;
		transition: all 0.3s;
		backface-visibility: hidden;
		border-radius: 2px; }


	&.on span {
		background-color: transparent; }

	&.on span:before {
		transform: rotate(45deg) translate(-1px, 0px); }

	&.on span:after {
		transform: rotate(-45deg) translate(6px, -7px); } }


.before_after.wrap-pics .after-clean, .before_after.wrap-pics .before-clean {
	font-weight: bold;
	background: rgba(#000, .4); }




@import "media"; // Всегда в конце
