$osr: "OpenSansRegular", sans-serif;
$osb: "OpenSansBold", sans-serif;

$rcb: "RobotoCondensedBold", sans-serif;
$rcr: "RobotoCondensedRegular", sans-serif;

$a: Arial, sans-serif;

$or: #FFCD00;
$gr: #00B600;
$grey: #312E29;

$green: #FFCD00;
