@import "_mixins/font-face";

@include font-face("roboto-example", "../fonts/RobotoRegular/RobotoRegular");
@include font-face("OpenSansRegular", "../fonts/OpenSansRegular/OpenSansRegular");
@include font-face("OpenSansBold", "../fonts/OpenSansBold/OpenSansBold");
@include font-face("RobotoCondensedBold", "../fonts/RobotoCondensedBold/RobotoCondensedBold");
@include font-face("RobotoCondensedRegular", "../fonts/RobotoCondensedRegular/RobotoCondensedRegular");



//+font-face("fontawesome", "../libs/font-awesome/fonts/fontawesome-webfont")
