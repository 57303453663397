@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;

		@if $asset-pipeline == true {
			src: font-url('#{$file-path}.eot');
			src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.woff') format('woff'), font-url('#{$file-path}.ttf') format('truetype'); }
		@else {
			src: url('#{$file-path}.eot');
			src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'); } } }

@mixin before($ct: "") {

	content: $ct;
	position: absolute;
	width: 100%;
	height: 100%; }

@mixin btl($rad) {
	border-top-left-radius: $rad; }
@mixin bbl($rad) {
	border-bottom-left-radius: $rad; }
@mixin btr($rad) {
	border-top-right-radius: $rad; }
@mixin bbr($rad) {
	border-bottom-right-radius: $rad; }

@mixin prev() {
	content: "\f105";
	font-family: FontAwesome; }

@mixin next() {
	content: "\f105";
	font-family: FontAwesome; }

