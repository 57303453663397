body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td {
	margin: 0;
	padding: 0; }

table {
	border-collapse: collapse;
	border-spacing: 0; }

fieldset,img,abbr {
	border: 0; }

address,caption,cite,code,dfn,em,strong,th,var {
	font-style: normal;
	font-weight: normal; }

ul li {
	list-style: none; }

caption,th {
	text-align: left; }

h1,h2,h3,h4,h5,h6 {
	font-size: 100%;
	font-weight: normal; }

sup {
	vertical-align: text-top; }

sub {
	vertical-align: text-bottom; }

input,textarea,select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit; }

legend {
	color: #000; }

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main {
	display: block; }
input , textarea, select {
	outline: none; }



img {
	max-width: 100%;
	height: auto; }
button {
	cursor: pointer; }
a {
	display: inline-block;
	text-decoration: none; }

a:active, a:focus {
	outline: none; }
.slick-slide, a, button, .button {
	outline: none; }



