@import url(../libs/bootstrap-grid/bootstrap-grid.css);

@import url(../libs/font-awesome/css/font-awesome.min.css);

@import url(../libs/magnific-popup/magnific-popup.css);

@import url(../libs/slick_work/slick.css);

@import url(../libs/slick_work/slick-theme.css);

@import url(../libs/before/ui/jquery-ui.css);

@import url(../libs/before/beforeAfter.css);

@font-face {
  font-family: "roboto-example";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/RobotoRegular.eot");
  src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansRegular";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/OpenSansRegular/OpenSansRegular.eot");
  src: url("../fonts/OpenSansRegular/OpenSansRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansRegular/OpenSansRegular.woff") format("woff"), url("../fonts/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansBold";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/OpenSansBold/OpenSansBold.eot");
  src: url("../fonts/OpenSansBold/OpenSansBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBold/OpenSansBold.woff") format("woff"), url("../fonts/OpenSansBold/OpenSansBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoCondensedBold";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoCondensedBold/RobotoCondensedBold.eot");
  src: url("../fonts/RobotoCondensedBold/RobotoCondensedBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoCondensedBold/RobotoCondensedBold.woff") format("woff"), url("../fonts/RobotoCondensedBold/RobotoCondensedBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoCondensedRegular";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.eot");
  src: url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.woff") format("woff"), url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.ttf") format("truetype");
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

input,
textarea,
select {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

a {
  display: inline-block;
  text-decoration: none;
}

a:active,
a:focus {
  outline: none;
}

.slick-slide,
a,
button,
.button {
  outline: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

*:-moz-placeholder {
  color: #666;
  opacity: 1;
}

*::-moz-placeholder {
  color: #666;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: #666;
}

body input:required:valid,
body textarea:required:valid {
  color: #666;
}

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.4;
  font-family: "OpenSansRegular", sans-serif;
  overflow-x: hidden;
  color: #000;
}

img {
  max-width: 100%;
}

.hidden {
  display: none;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

a {
  transition: all 0.3s;
  text-decoration: none;
  color: #000;
}

a:hover {
  opacity: .8;
}

img,
input,
textarea {
  max-width: 100%;
}

#thanks .form-item {
  font-size: 18px;
  text-align: center;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.wrap-items {
  display: flex;
  flex-wrap: wrap;
}

.button,
a.button,
button {
  border: none;
}

.button:hover,
a.button:hover,
button:hover {
  opacity: .8;
}

.button-block .button-form {
  color: #fff;
  border-width: 3px;
  border-style: solid;
  display: block;
  font-family: "RobotoCondensedBold", sans-serif;
  font-size: 20px;
  line-height: 40px;
  width: 240px;
  margin: 0 auto;
  text-transform: uppercase;
  border-radius: 11px;
  text-shadow: 1px 1px #2F2F2F;
}

.button-block .button-form.button-blue {
  background: linear-gradient(to top, #0FAFEC 0%, #0093CB 100%);
}

.button-block .button {
  border-bottom: 3px solid #fff;
  border-radius: 40px;
  font-family: "RobotoCondensedBold", sans-serif;
  font-size: 27px;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  display: table;
  margin: 0 auto;
  padding: 14px 30px;
  position: relative;
  background-color: #fff;
  font-weight: bold;
  background-color: #FFC900;
  box-shadow: 0 3px 0px #C39C00;
  position: relative;
  cursor: pointer;
}

.button-block .button:before {
  content: "";
  position: absolute;
  width: 90%;
  height: 1px;
  border-bottom: 1px dashed #000;
  bottom: 15px;
  left: 5%;
}

.button-block .button:hover {
  background: #413F3A;
  color: #fff;
  opacity: 1;
  box-shadow: 0 3px 0px #999;
}

.button-block .button:hover:before {
  border-bottom: 1px dashed #fff;
}

.button-block .button-dark {
  border-bottom: 3px solid #fff;
  border-radius: 40px;
  font-family: "RobotoCondensedBold", sans-serif;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: table;
  margin: 0 auto;
  padding: 14px 30px;
  position: relative;
  background-color: #fff;
  font-weight: bold;
  background-color: #413f3a;
  box-shadow: 0 3px 0px #C5C5C5;
  position: relative;
}

.button-block .button-dark:hover {
  background: #FFCD00;
  color: #2B2306;
  opacity: 1;
  box-shadow: 0 3px 0px #C39C00;
}

.section {
  padding: 60px 0;
}

h1,
.h1 {
  font-family: Arial, sans-serif;
  font-size: 42px;
  font-weight: bold;
  padding: 12px 50px;
  text-align: center;
  box-shadow: 2px 4px 0px #C19900;
  background: #FFCD00;
  border-radius: 7px;
}

h2,
.h2 {
  font-family: "RobotoCondensedBold", sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
}

h2.figure,
.h2.figure {
  font-family: "RobotoCondensedBold", sans-serif;
  font-size: 27px;
  text-transform: uppercase;
  background: #fff;
  color: #413f3a;
  border-bottom: 3px solid #FFCD00;
  border-radius: 40px;
  padding: 14px 30px;
  display: table;
  margin: 0 auto 50px;
  position: relative;
}

h2.figure:before,
.h2.figure:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 58px 0 58px;
  border-color: #ffcc00 transparent transparent transparent;
  bottom: -26px;
  left: calc(50% - 58px);
}

h3,
.h3 {
  font-size: 24px;
  line-height: 1;
  font-family: "RobotoCondensedBold", sans-serif;
  color: #413f3a;
  text-align: center;
}

.top-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-header-wrap ul {
  height: 37px;
  margin-top: 3px;
}

.top-header-wrap ul li {
  float: left;
  font-size: 24px;
}

.top-header-wrap ul a {
  margin: 0px 10px 0 10px;
  padding: 0px 10px 0px 10px;
  background: #FFCD00;
  transition: 0.4s;
  border-radius: 10px;
  position: relative;
}

.top-header-wrap ul a:hover {
  border-top: solid 3px #000;
}

.top-header {
  font-family: "RobotoCondensedBold", sans-serif;
  background: #fff;
  box-shadow: 0 0px 13px #D7D7D7;
  padding: 0 0 10px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
}

.top-header .img-logo {
  text-transform: uppercase;
  font-size: 43px;
  line-height: 1;
}

.top-header .logo-text {
  background: #FFCD00;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0 15px;
}

.s-header {
  background: url("../img/header-bg.jpg") 0 0;
  background-size: cover;
  padding-top: 130px;
  padding-bottom: 25px;
}

.s-header h1 {
  display: block;
  margin: 0 25px;
}

.s-header .descr {
  background: #FDD72C;
  font-family: Arial,sans-serif;
  font-size: 23px;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 70px;
  right: 25px;
  transform: rotate(-3deg);
  padding: 15px 40px;
  border-radius: 10px;
}

.s-header .h1-block {
  position: relative;
  z-index: 5;
}

.s-header .wrap-blocks {
  display: flex;
  justify-content: space-between;
}

.s-header .wrap-action {
  position: relative;
}

.s-header .action-block {
  width: 335px;
  background: rgba(28, 28, 28, 0.76);
  text-align: center;
  position: relative;
  margin-top: -100px;
  border-radius: 20px;
  padding-top: 120px;
  padding-bottom: 20px;
  color: #fff;
  font-size: 40px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-shadow: 0px 4px 10px #000;
}

.s-header .action-block:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../img/uzor.png");
  top: 0;
  left: 0;
}

.s-header .action-block h3 {
  color: #FFCD00;
  text-transform: uppercase;
  line-height: 1;
}

.s-header .action-block .num-block {
  font-size: 100px;
  color: #FFCD00;
}

.s-header .action-block .if {
  font-size: 25px;
  color: #FFCD00;
  margin-bottom: 15px;
}

.s-header .action-block-2 {
  min-width: 330px;
  max-width: 330px;
  border-radius: 10px;
  background: rgba(251, 247, 247, 0.89);
  font-size: 14px;
  font-family: Arial, sans-serif;
  padding: 0 25px 20px;
  margin-top: 65px;
  position: relative;
}

.s-header .action-block-2 .title {
  font-size: 23px;
  font-weight: bold;
  background: url("../img/calcul_zag.png") no-repeat;
  width: 337px;
  height: 100px;
  margin-left: -15px;
  text-align: center;
  font-weight: bold;
  padding-top: 8px;
  position: absolute;
  top: 0;
  left: -25px;
}

.s-header .action-block-2 .title p {
  transform: rotate(-3deg);
}

.s-header .action-block-2 .content-block {
  padding-top: 100px;
  width: 230px;
  margin: auto;
  text-align: center;
}

.s-header .action-block-2 label span {
  font-weight: bold;
  margin-bottom: 10px;
}

.s-header .action-block-2 .button-block p {
  font-size: 18px;
  margin: 15px 0;
}

.s-header input {
  width: 100%;
  height: 41px;
  font-size: 24px;
  text-align: center;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 24px;
  background: #fff;
  border-top: 3px solid #ddd;
}

.s-header .people-block {
  position: absolute;
  top: 216px;
  right: -335px;
  z-index: 9;
}

.s-header .people-block img {
  width: 377px;
  max-width: 377px;
}

.s-header .sale p {
  color: #FFCD00;
}

.s-advantages {
  background: url("../img/advantages-bg.jpg") 50% no-repeat;
  text-align: center;
  padding: 87px 0 47px;
  min-height: 580px;
}

.s-advantages .content-block {
  text-transform: uppercase;
  color: #FFCD00;
  font-family: "RobotoCondensedBold", sans-serif;
  font-size: 24px;
  line-height: 1;
  margin: 25px 0;
}

.s-advantages .content-block span {
  display: block;
  color: #000;
}

.s-advantages .wrap-items {
  justify-content: space-between;
}

.s-readyGoods {
  margin-top: -180px;
  padding-bottom: 80px;
}

.s-readyGoods .text-block h3 span {
  display: block;
}

.s-readyGoods .wrap-item {
  display: flex;
  justify-content: space-between;
  background: #F4F4F4;
}

.s-readyGoods .img-block {
  border: 14px solid #fff;
  margin: -77px 0 0 39px;
  position: relative;
  box-shadow: 0px 2px 6px #9E9E9E;
  border-radius: 10px;
}

.s-readyGoods .text-block {
  width: 480px;
  margin-top: -80px;
}

.s-readyGoods h2 {
  margin-bottom: -10px;
}

.s-readyGoods .complect {
  flex-basis: 290px;
  border-bottom: 1px solid #BBBBBB;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 5px 0 5px 18px;
  background: #fff;
}

.s-readyGoods .complect-price {
  flex-grow: 1;
  background: #00B600;
  color: #fff;
  justify-content: center;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
}

.s-readyGoods .item-table {
  display: flex;
  font-family: "OpenSansBold", sans-serif;
  font-size: 18px;
  min-height: 35px;
}

.s-readyGoods .table-block {
  box-shadow: 0px 2px 6px #9E9E9E;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 20px;
}

.s-readyGoods .round-block {
  font-family: "OpenSansBold", sans-serif;
  width: 133px;
  height: 133px;
  border-radius: 50%;
  background: #34322d;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  left: -30px;
  bottom: -45px;
}

.s-readyGoods .area {
  font-size: 26px;
  color: #fff;
  flex-basis: 100%;
  margin-top: 15px;
  position: relative;
}

.s-readyGoods .area:before {
  content: "";
  position: absolute;
  width: 85px;
  height: 1px;
  background: #FFCD00;
  bottom: -7px;
  left: calc(50% - 42.5px);
}

.s-readyGoods .price {
  color: #FFCD00;
  font-size: 24px;
  flex-basis: 100%;
  margin-bottom: 25px;
}

.s-readyGoods .item {
  margin-top: 160px;
}

.s-readyGoods .angle-block {
  border-style: solid;
  border-width: 127px 570px 0 570px;
  border-color: #f4f4f4 transparent transparent;
  width: 0;
  height: 0;
}

.s-readyGoods .slick-next {
  top: 25%;
  right: -40px;
}

.s-readyGoods .slick-next:before {
  color: #312E29;
}

.s-readyGoods .slick-next:before:hover:before {
  color: #00B600;
}

.s-readyGoods .slick-prev {
  top: 25%;
  left: -40px;
}

.s-readyGoods .slick-prev:before {
  color: #312E29;
}

.s-readyGoods .slick-prev:before:hover:before {
  color: #00B600;
}

.s-readyGoods .second-arr,
.s-readyGoods .second-arr-l {
  top: 75%;
}

.bg-block {
  background: url("../img/bg_repeat.png");
  height: 36px;
}

.big-section {
  background: url("../img/fon-big-sec.jpg") no-repeat 50%;
  min-height: 2050px;
}

.s-big-1 {
  padding: 50px 0 0;
  color: #34322d;
  font-family: "OpenSansRegular", sans-serif;
}

.s-big-1 .wrap-items {
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 980px;
  margin: auto;
}

.s-big-1 h3 {
  color: #343223;
  font-size: 30px;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 20px;
}

.s-big-1 .img-block {
  text-align: center;
}

.s-big-1 .wrap-img-block {
  height: 240px;
  width: 240px;
  border-radius: 50%;
  border: 7px solid #fff;
  overflow: hidden;
}

.s-big-1 .wrap-img-block img {
  max-width: 1000%;
  display: block;
}

.s-big-1 .text-block {
  flex-basis: 620px;
  font-size: 18px;
  line-height: 1.2;
}

.s-big-1 .item {
  margin-bottom: 70px;
}

.s-scheme {
  text-align: center;
}

.s-scheme .slick-next,
.s-scheme .slick-prev {
  width: 70px;
  height: 70px;
  background: #312E29;
  border-radius: 50%;
}

.s-scheme img {
  display: inline-block;
}

.s-scheme .img {
  text-align: center;
  max-width: 400px;
  margin: auto;
}

.s-scheme .slick-dots li button:before {
  font-size: 26px;
}

.s-scheme .slick-dots {
  bottom: -50px;
}

.s-scheme .text {
  margin: 25px auto 0;
  font-size: 24px;
  line-height: 1.2;
  font-family: "RobotoCondensedBold", sans-serif;
  text-transform: uppercase;
  max-width: 300px;
}

.s-scheme .slick-prev {
  left: -70px;
}

.s-scheme .slick-next {
  right: -70px;
}

.s-scheme .button-block {
  margin-top: 70px;
}

.s-scheme .wrap-item {
  padding: 0 10px;
}

.s-dop {
  margin: 50px 0 0;
}

.s-dop .wrap-items {
  justify-content: space-between;
  margin-top: 100px;
}

.s-dop .content-block {
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  font-family: "RobotoCondensedBold", sans-serif;
  text-align: center;
  text-shadow: 0px 5px 10px #272727;
  margin-top: 30px;
}

.s-dop .content-block span {
  display: block;
}

.s-galery .slider-3 {
  margin: 0 -10px;
}

.s-galery .item {
  margin: 0 10px;
}

.s-galery .slick-next {
  height: 100%;
  width: 80px;
  background: rgba(255, 255, 255, 0.6);
  right: 10px;
}

.s-galery .slick-next:before {
  color: #312E29;
  font-size: 100px;
}

.s-galery .slick-prev {
  height: 100%;
  width: 80px;
  background: rgba(255, 255, 255, 0.6);
  left: 10px;
}

.s-galery .slick-prev:before {
  color: #312E29;
  font-size: 100px;
}

.s-galery .slider-3 {
  margin: 80px 0;
}

.table-color .table-block {
  box-shadow: 0px 2px 6px #9E9E9E;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 20px;
}

.table-color .table-block .complect {
  flex-basis: 290px;
  border-bottom: 1px solid #BBBBBB;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 5px 0 5px 18px;
  background: #fff;
}

.table-color .table-block .complect-price {
  flex-grow: 1;
  background: #00B600;
  color: #fff;
  justify-content: center;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
}

.table-color .table-block .item-table {
  display: flex;
  font-family: "OpenSansBold", sans-serif;
  font-size: 18px;
  min-height: 35px;
}

.s-prices {
  padding-bottom: 0;
  padding-top: 0;
}

.s-prices .table-color {
  width: 570px;
}

.s-prices .complect {
  flex-basis: 410px;
  min-width: 410px;
}

.s-prices .complect-price {
  flex-grow: 0;
}

.s-prices .wrap-items {
  justify-content: space-around;
  align-items: center;
}

.s-review .wrap-item {
  display: flex;
  align-items: center;
}

.s-review .img-block {
  min-width: 190px;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  border: 4px solid #FFCD00;
  overflow: hidden;
  margin-right: 30px;
}

.s-review .img-block img {
  width: 100%;
}

.s-review .title {
  font-size: 22px;
  font-family: "OpenSansRegular", sans-serif;
  color: #2f2d29;
  margin-bottom: 15px;
}

.s-review .slick-dots li button:before {
  font-size: 15px;
}

.s-review .content-block {
  font-size: 16px;
  line-height: 1.5;
}

.s-manager .wrap-items {
  justify-content: space-between;
  margin-top: 40px;
}

.s-manager h2 {
  color: #FFCD00;
  font-family: "RobotoCondensedBold", sans-serif;
  font-size: 56px;
  line-height: 1.1;
}

.s-manager .descr {
  color: #413f3a;
  font-size: 38px;
  line-height: 1.15;
  font-family: "RobotoCondensedBold", sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.s-manager .content-block p {
  color: #2f2d29;
  font-family: "RobotoCondensedRegular", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  margin: 12px 0 21px;
}

.s-manager .content-block span {
  display: block;
}

.s-manager .img-block {
  overflow: hidden;
}

.s-manager .img-block img {
  border-radius: 50%;
  height: 182px;
  width: 182px;
}

.s-manager .button-block-but {
  margin-top: 80px;
}

.s-manager .button-block-but .button:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 58px 0 58px;
  border-color: #fc0 transparent transparent transparent;
  top: -26px;
  left: calc(50% - 58px);
}

.free-bot .section-wrap {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background: #d8e8d2;
  font-family: "RobotoCondensedBold", sans-serif;
  font-size: 30px;
  color: #5E7556;
  padding: 30px 0 20px;
  text-align: center;
}

footer {
  padding: 20px 0;
  background: #413f3a;
  color: #fff;
  font-family: "RobotoCondensedBold", sans-serif;
}

footer .logo {
  text-align: center;
}

footer .logo a {
  color: #fff;
  font-size: 36px;
  text-transform: uppercase;
}

footer .logo-text {
  font-size: 16px;
  background: #FFCD00;
  padding: 0 30px;
  color: #000;
}

footer .section-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer a {
  color: #fff;
}

footer .contact-block {
  font-size: 18px;
}

footer .social-block {
  display: block !important;
}

footer .social-block .social-item {
  display: inline-block;
  font-size: 40px;
  margin: 0 10px;
}

#container-before-after img {
  width: 100%;
  height: 480px;
}

.s-calc {
  padding: 80px 0;
}

.s-calc .figure {
  font-size: 27px;
}

.error {
  display: none;
  color: red;
}

.wrap-quiz-items {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.wrap-quiz-items input {
  height: 40px;
  width: 250px;
  border: 2px solid #FFCD00;
  text-indent: 5px;
  padding: 0 10px;
}

.wrap-quiz-items .js-progress {
  width: 350px;
  height: 10px;
  border-radius: 3px;
  border: 2px solid #FFCD00;
  margin-top: 2px;
}

.wrap-quiz-items .progress {
  margin-top: 40px;
}

.wrap-quiz-items .jspesent {
  display: block;
  height: 100%;
  background: #cca400;
}

.quiz-item:not(.quiz-item-1) {
  display: none;
  background: #FFCD00;
}

.quiz-item:not(.quiz-item-1) .wrap-items {
  justify-content: space-around;
  margin: 0 0 auto;
}

.quiz-item:not(.quiz-item-1) .wrap-quiz:not(.quiz-item-1) {
  background: #fff;
  max-width: 760px;
  flex-basis: 760px;
  padding: 20px;
  border-radius: 25px;
  margin: auto;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.quiz-item:not(.quiz-item-1) .prwv-h3 {
  margin-bottom: 30px;
  position: relative;
  padding-left: 30px;
}

.quiz-item:not(.quiz-item-1) .prwv-h3:before {
  content: "";
  position: absolute;
  width: 250px;
  height: 2px;
  left: 0;
  bottom: -10px;
  background: #FFCD00;
}

.quiz-item:not(.quiz-item-1) .prwv-h3:after {
  content: "\2714";
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #FFCD00;
  text-align: center;
  line-height: 16px;
}

.quiz-item:not(.quiz-item-1) h3 {
  font-size: 30px;
  margin: 25px 0;
}

.quiz-item:not(.quiz-item-1) .button-block {
  display: flex;
  justify-content: center;
  margin: 20px 0 30px;
}

.quiz-item:not(.quiz-item-1) .button {
  margin: 0 30px;
  font-size: 22px;
  min-width: 200px;
  padding: 10px 30px;
  position: relative;
  white-space: nowrap;
}

.quiz-item:not(.quiz-item-1) .button:before {
  width: 80%;
  left: 10%;
  bottom: 8px;
}

.quiz-item:not(.quiz-item-1) .button.disabled {
  background: #cca400;
}

.quiz-item-4 .descr-item {
  margin-bottom: 5px;
}

.quiz-item-1 {
  background: url("../img/quiz-bg-1.jpg") no-repeat 50% 50%;
  background-size: cover;
  color: #eef8ff;
  text-align: center;
}

.quiz-item-1 h2 {
  margin-bottom: 20px;
  font-size: 50px;
}

.quiz-item-1 .button-block {
  margin: 30px 0;
}

.quiz-item-1 .descr {
  font-size: 24px;
}

.quiz-item-1 .descr p {
  margin: 15px 0;
}

.quiz-item-1 .wrap-quiz {
  max-width: 930px;
  flex-basis: 930px;
}

.quiz-item-1 .wrap-quiz {
  max-width: 930px;
  background: rgba(30, 30, 30, 0.5);
  padding: 20px;
  border-radius: 25px;
  margin: 50px auto 0;
}

.quiz-item-2 {
  background: #FFCD00;
}

.quiz-item-2 .wrap-quiz {
  background: #fff;
}

.quiz-item-2 .wrap-quiz h3 {
  margin-top: 15px;
}

.quiz-item-2 label {
  cursor: pointer;
  text-align: center;
  position: relative;
  display: block;
}

.quiz-item-2 label:hover:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.6);
  right: 10px;
  top: 10px;
  border-radius: 50%;
}

.quiz-item-2 label.active:after {
  content: "\2714";
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid #FFCD00;
  border-radius: 50%;
  color: #fff;
  line-height: 16px;
  font-size: 14px;
  box-shadow: 0 0 10px #FFCD00;
}

.quiz-item-2 label input {
  display: none;
}

.quiz-item-2 .descr-item {
  margin: 15px 0;
}

.quiz-item-3 .wrap-items,
.quiz-item-5 .wrap-items {
  display: block;
}

.quiz-item-3 .wrap-quiz,
.quiz-item-5 .wrap-quiz {
  background: #fff;
}

.quiz-item-3 label,
.quiz-item-5 label {
  cursor: pointer;
  position: relative;
  display: block;
}

.quiz-item-3 label:before,
.quiz-item-5 label:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0px;
  width: 20px;
  height: 20px;
  border: 2px solid #FFCD00;
  border-radius: 50%;
}

.quiz-item-3 label:hover:before,
.quiz-item-5 label:hover:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.6);
  left: 10px;
  top: 0px;
  border-radius: 50%;
}

.quiz-item-3 label.active:after,
.quiz-item-5 label.active:after {
  content: "\2714";
  text-align: center;
  position: absolute;
  left: 10px;
  top: 0px;
  width: 20px;
  height: 20px;
  border: 2px solid #FFCD00;
  border-radius: 50%;
  color: #000;
  line-height: 16px;
  font-size: 14px;
  box-shadow: 0 0 10px #FFCD00;
}

.quiz-item-3 label input,
.quiz-item-5 label input {
  display: none;
}

.quiz-item-3 .descr-item,
.quiz-item-5 .descr-item {
  margin: 15px 0;
  padding-left: 50px;
}

.quiz-item-4 .wrap-items {
  align-items: flex-end;
}

.quiz-item-4 .wrap-quiz h3 {
  margin: 15px 0;
}

.quiz-item-4 .item {
  flex-basis: 48%;
  margin-bottom: 15px;
}

.thank-page {
  height: calc(100vh - 140px);
  padding-top: 0;
}

.thank-page .descr-1 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}

.thank-page .h1-block {
  background: rgba(255, 255, 255, 0.5);
  padding: 50px 0;
}

.thank-page .section-wrap {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  justify-content: center;
}

.thank-page .button-block {
  margin-top: 30px;
}

.than-page .navigation-wrap {
  display: none;
}

.slick-next {
  width: 26px;
  height: 45px;
  z-index: 99;
}

.slick-next::before {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 70px;
}

.slick-prev {
  width: 26px;
  height: 45px;
  z-index: 99;
}

.slick-prev::before {
  content: "\f104";
  font-family: FontAwesome;
  font-size: 70px;
}

.slick-dots li.slick-active button:before {
  color: #FFCD00;
  opacity: 1;
}

.slick-dots li button:before {
  color: #312E29;
  opacity: 1;
}

.mfp-bg {
  background: #eee;
}

.mfp-close-btn-in .mfp-close {
  color: #fff;
}

.form-popup,
.form-dark {
  max-width: 330px;
  background: rgba(28, 28, 28, 0.76);
  color: #fff;
  padding: 40px 25px;
  border-radius: 20px;
  position: relative;
  margin: auto;
}

.form-popup .form-title,
.form-dark .form-title {
  font-size: 30px;
  font-family: "RobotoCondensedBold", sans-serif;
  text-align: center;
  margin-bottom: 25px;
}

.form-popup input,
.form-dark input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  text-indent: 10px;
}

.form-popup input[type=checkbox],
.form-dark input[type=checkbox] {
  width: 16px;
  height: 16px;
}

.form-popup label,
.form-dark label {
  margin-bottom: 10px;
  display: block;
}

.form-popup .button-form,
.form-dark .button-form {
  width: 100%;
  display: block;
  margin: 15px 0;
  background: #FFCD00;
  text-transform: none;
  color: #000;
  border-color: #fff;
  text-shadow: none;
}

.check-block {
  font-size: 10px;
}

.check-block label {
  display: flex;
  align-items: center;
}

.check-block input {
  margin-right: 10px;
  min-width: 16px;
}

.close {
  display: none;
  font-size: 30px;
}

.mobil-mnu {
  display: none;
}

.mobil-mnu {
  background-color: #fff;
  color: #fff;
}

.toggle-mnu {
  display: block;
  width: 28px;
  height: 28px;
}

.toggle-mnu span:after,
.toggle-mnu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
}

.toggle-mnu span:after {
  top: 18px;
}

.toggle-mnu span {
  position: relative;
  display: block;
}

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}

.toggle-mnu.on span {
  background-color: transparent;
}

.toggle-mnu.on span:before {
  transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-mnu.on span:after {
  transform: rotate(-45deg) translate(6px, -7px);
}

.before_after.wrap-pics .after-clean,
.before_after.wrap-pics .before-clean {
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
}

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */

/* Medium Devices, Desktops */

/* Small Devices, Tablets */

/* Extra Small Devices, Phones */

/* Custom, iPhone Retina */

/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */

/* Extra Small Devices, Phones */

/* Small Devices, Tablets */

/* Medium Devices, Desktops */

/* Large Devices, Wide Screens */

@media only screen and (min-width: 320px) {
  /**/
}

@media only screen and (min-width: 480px) {
  /**/
}

@media only screen and (min-width: 768px) {
  /**/
}

@media only screen and (min-width: 992px) {
  /**/
}

@media only screen and (min-width: 1200px) {
  /**/
}

@media only screen and (max-width: 1200px) {
  /**/

  .top-header {
    padding-top: 5px;
  }

  .top-header .logo-text {
    font-size: 14px;
    white-space: nowrap;
  }

  .top-header .img-logo {
    font-size: 35px;
  }

  .top-header-wrap ul li {
    font-size: 20px;
  }

  .h1,
  h1 {
    font-size: 38px;
  }

  .s-readyGoods .angle-block {
    border-width: 115px 472px 0 472px;
  }
}

@media only screen and (max-width: 992px) {
  /**/

  .top-header-wrap .close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 30px;
    cursor: pointer;
    display: block;
    width: 30px;
  }

  .top-header-wrap ul {
    height: auto;
  }

  .top-header-wrap .navigation {
    position: absolute;
    right: 0;
    top: 65px;
    width: 320px;
    background-color: #fff;
    z-index: 99;
    padding: 15px;
    border-bottom-left-radius: 20px;
    display: none;
  }

  .top-header-wrap .navigation li {
    float: none;
    margin: 15px 0;
    min-height: 51px;
  }

  .top-header-wrap .navigation li a {
    padding: 10px 15px;
  }

  .mobil-mnu {
    display: block;
  }

  .h1,
  h1 {
    padding: 12px 25px;
    font-size: 30px;
  }

  .s-header .descr {
    font-size: 20px;
  }

  .s-advantages .item {
    flex-basis: 20%;
  }

  .s-advantages img {
    max-width: 90px;
  }

  .s-advantages .content-block {
    font-size: 20px;
  }

  .s-advantages {
    min-height: 510px;
  }

  .s-readyGoods .angle-block {
    display: none;
  }

  .s-readyGoods .wrap-item {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .s-readyGoods .img-block {
    margin: 0;
    max-width: 430px;
  }

  .s-readyGoods .text-block {
    margin: 0;
    margin-top: 30px;
    max-width: 480px;
  }

  .s-readyGoods .container {
    width: 100%;
    padding: 0;
  }

  .s-readyGoods .wrap-item {
    position: relative;
  }

  .s-readyGoods .wrap-item h3 {
    position: absolute;
    left: 0;
    top: -65px;
    width: 100%;
  }

  .s-readyGoods .item:nth-child(even) {
    margin-top: 85px;
  }

  .s-readyGoods .slick-prev {
    left: 30px;
  }

  .s-readyGoods .slick-next {
    right: 30px;
  }

  .s-big-1 .wrap-items {
    display: block;
  }

  .s-big-1 .wrap-img-block {
    margin: 0 auto 30px;
    height: 225px;
    width: 225px;
  }

  .big-section {
    background: 0 0;
    text-align: center;
  }

  .big-section h3 {
    text-align: center;
  }

  .s-dop .wrap-items {
    justify-content: space-around;
  }

  .s-dop .wrap-items .item {
    margin-bottom: 25px;
  }

  .s-prices .table-color {
    margin-bottom: 30px;
  }

  .s-manager .wrap-items {
    justify-content: space-around;
  }

  .s-manager .wrap-items .item {
    margin-bottom: 30px;
  }

  .s-header .people-block {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /**/

  .s-header .action-block {
    margin-top: 0;
    flex-basis: 100%;
  }

  .s-header .action-block-2 {
    margin-top: 30px;
  }

  .s-header .action-block-2 .wrap-action {
    margin-top: 20px;
  }

  .s-header .wrap-blocks {
    flex-wrap: wrap;
  }

  .s-header .h1-block {
    position: absolute;
    top: 20px;
  }

  .s-header {
    padding-top: 90px;
  }

  .s-header .section-wrap {
    position: relative;
  }

  .s-header .left {
    position: relative;
    margin: auto;
  }

  .s-header .wrap-action {
    margin-top: 80px;
  }

  .s-advantages .item {
    flex-basis: 30%;
  }

  .s-advantages .wrap-items {
    justify-content: center;
  }

  .s-readyGoods {
    margin-top: -30px;
  }

  .s-readyGoods .slick-next,
  .s-readyGoods .slick-prev {
    top: 54%;
  }

  .s-manager .wrap-items .item {
    flex-basis: 45%;
  }

  .s-manager .img-block {
    text-align: center;
  }

  .s-manager h2 {
    font-size: 40px;
  }

  .s-manager .descr {
    font-size: 28px;
  }

  .s-header .descr {
    display: none;
  }

  .s-readyGoods .img-block {
    max-width: 100%;
  }

  .s-readyGoods .text-block {
    max-width: 100%;
  }

  .s-readyGoods .complect {
    flex-basis: 70%;
  }

  .s-readyGoods .area {
    font-size: 22px;
  }

  .s-readyGoods .price {
    font-size: 18px;
  }

  .s-readyGoods .round-block {
    left: 15px;
    width: 120px;
    height: 120px;
  }

  .s-readyGoods .item-table {
    font-size: 14px;
  }

  .s-readyGoods .item {
    margin-top: 120px;
  }

  .s-big-1 .text-block {
    font-size: 16px;
  }

  .button-block .button {
    font-size: 20px;
  }

  .s-prices .complect {
    min-width: 0;
  }

  .s-prices .table-color .table-block .complect {
    flex-basis: 70%;
    max-width: 70%;
  }

  .s-review .wrap-item {
    display: block;
  }

  .table-color .table-block .item-table {
    font-size: 16px;
  }

  .s-review .img-block {
    text-align: center;
    margin: 15px auto;
  }

  .s-review .title {
    font-size: 20px;
    text-align: center;
  }

  .free-bot .section-wrap {
    font-size: 20px;
  }

  footer .logo a {
    font-size: 20px;
  }

  footer .logo-text {
    font-size: 12px;
  }

  .s-scheme .slick-dots li button:before {
    font-size: 16px;
  }

  .s-galery .slider-3 {
    text-align: center;
  }

  .s-galery .slider-3 img {
    display: inline-block;
  }

  .quiz-item:not(.quiz-item-1) .wrap-quiz:not(.quiz-item-1),
  .quiz-item-1 .wrap-quiz {
    max-width: 90%;
  }

  .quiz-item-1 h2 {
    font-size: 35px;
  }

  .quiz-item-1 .descr {
    font-size: 18px;
  }

  .quiz-item-4 .descr-item {
    font-size: 14px;
    line-height: 1.2;
  }

  .quiz-item .button-block {
    flex-wrap: wrap;
  }

  .quiz-item .button-block .button {
    margin-bottom: 15px;
  }

  .quiz-item:not(.quiz-item-1) .wrap-quiz:not(.quiz-item-1) {
    height: auto;
  }

  .quiz-item .item {
    margin-bottom: 15px;
  }

  .quiz-item .wrap-quiz-items .wrap-quiz {
    margin: 20px 0;
  }

  .quiz-item {
    padding: 20px 0;
  }

  footer .section-wrap {
    flex-direction: column;
    padding: 0 10px;
  }

  footer .logo {
    margin: 15px 0;
  }

  .quiz-item-4 .wrap-items {
    display: block;
  }

  .thank-page {
    padding-top: 0;
  }

  .thank-page .h1-block {
    position: static;
  }
}

@media only screen and (max-width: 480px) {
  /**/

  .h1,
  h1 {
    font-size: 25px;
  }

  .s-header .action-block {
    font-size: 30px;
  }

  .s-header .action-block-2 {
    min-width: 290px;
    max-width: 290px;
  }

  .s-header .action-block-2 .title {
    width: 300px;
    font-size: 20px;
  }

  .s-advantages .item {
    flex-basis: 50%;
  }

  .s-header .action-block .num-block {
    font-size: 54px;
  }

  .form-dark,
  .form-popup {
    max-width: 290px;
  }

  .h2.figure,
  h2.figure {
    font-size: 24px;
  }

  .button-block .button {
    padding: 12px 15px;
  }

  .s-review .content-block {
    font-size: 14px;
  }

  body {
    font-size: 14px;
  }

  .s-manager h2 {
    font-size: 30px;
  }

  .s-manager .descr {
    font-size: 22px;
  }

  .s-manager .wrap-items .item {
    flex-basis: 100%;
  }

  .table-color .table-block .complect-price {
    flex-basis: 120px;
  }

  .h3,
  h3 {
    font-size: 20px;
  }

  .top-header .img-logo {
    font-size: 24px;
  }

  .top-header .logo-text {
    font-size: 10px;
    padding: 0 5px;
  }

  .top-header-wrap .navigation {
    top: 53px;
    width: 100%;
  }

  .s-scheme .slick-next,
  .s-scheme .slick-prev {
    width: 40px;
    height: 40px;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
  }

  .quiz-item:not(.quiz-item-1) .wrap-quiz:not(.quiz-item-1) {
    padding: 20px 10px;
  }

  .wrap-quiz-items .js-progress {
    width: 100%;
  }

  .quiz-item:not(.quiz-item-1) .button {
    white-space: normal;
  }

  .quiz-item-1 h2 {
    font-size: 25px;
  }

  .quiz-item-1 .descr {
    font-size: 16px;
  }
}

@media only screen and (max-width: 320px) {
  /**/
}