@import "vars";

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	/**/
	.top-header {
		padding-top: 5px;
		.logo-text {
			font-size: 14px;
			white-space: nowrap; } }
	.top-header .img-logo {
		font-size: 35px; }
	.top-header-wrap ul li {
		font-size: 20px; }
	.h1, h1 {
		font-size: 38px; }
	.s-readyGoods .angle-block {
		border-width: 115px 472px 0 472px; } }

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	/**/
	.top-header-wrap {
		.close {
			position: absolute;
			right: 15px;
			top: 15px;
			font-size: 30px;
			cursor: pointer;
			display: block;
			width: 30px; }
		ul {
			height: auto; }
		.navigation {
			position: absolute;
			right: 0;
			top: 65px;
			width: 320px;
			background-color: #fff;
			z-index: 99;
			padding: 15px;
			@include bbl(20px);
			display: none;
			li {
				float: none;
				margin: 15px 0;
				min-height: 51px;
				a {
					padding: 10px 15px; } } } }
	.mobil-mnu {
		display: block; }
	.h1, h1 {
		padding: 12px 25px;
		font-size: 30px; }
	.s-header .descr {
		font-size: 20px; }
	.s-advantages {
		.item {
			flex-basis: 20%; }
		img {
			max-width: 90px; } }
	.s-advantages .content-block {
		font-size: 20px; }
	.s-advantages {
		min-height: 510px; }
	.s-readyGoods .angle-block {
		display: none; }
	.s-readyGoods .wrap-item {
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: column;
		align-items: center; }
	.s-readyGoods .img-block {
		margin: 0;
		max-width: 430px; }
	.s-readyGoods .text-block {
		margin: 0;
		margin-top: 30px;
		max-width: 480px; }
	.s-readyGoods {
		.container {
			width: 100%;
			padding: 0; }
		.wrap-item {
			position: relative;
			h3 {
				position: absolute;
				left: 0;
				top: -65px;
				width: 100%; } }
		.item {
			&:nth-child(even) {
				margin-top: 85px; } }
		.slick-prev {
			left: 30px; }
		.slick-next {
			right: 30px; } }
	.s-big-1 {
		.wrap-items {
			display: block; }
		.wrap-img-block {
			margin: 0 auto 30px;
			height: 225px;
			width: 225px; } }
	.big-section {
		background: 0 0;
		text-align: center;
		h3 {
			text-align: center; } }
	.s-dop .wrap-items {
		justify-content: space-around;
		.item {
			margin-bottom: 25px; } }
	.s-prices .table-color {
		margin-bottom: 30px; }
	.s-manager .wrap-items {
		justify-content: space-around;
		.item {
			margin-bottom: 30px; } }
	.s-header .people-block {
		display: none; } }


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	/**/
	.s-header {
		.action-block {
			margin-top: 0;
			flex-basis: 100%; }
		.action-block-2 {
			margin-top: 30px;
			.wrap-action {
				margin-top: 20px; } } }
	.s-header .wrap-blocks {
		flex-wrap: wrap; }
	.s-header .h1-block {
		position: absolute;
		top: 20px; }
	.s-header {
		padding-top: 90px;
		.section-wrap {
			position: relative; }
		.left {
			position: relative;
			margin: auto; } }

	.s-header .wrap-action {
		margin-top: 80px; }
	.s-advantages .item {
		flex-basis: 30%; }
	.s-advantages .wrap-items {
		justify-content: center; }
	.s-readyGoods {
		margin-top: -30px;
		.slick-next, .slick-prev {
			top: 54%; } }
	.s-manager .wrap-items .item {
		flex-basis: 45%; }
	.s-manager .img-block {
		text-align: center; }
	.s-manager h2 {
		font-size: 40px; }
	.s-manager .descr {
		font-size: 28px; }
	.s-header .descr {
		display: none; }
	.s-readyGoods .img-block {
		max-width: 100%; }
	.s-readyGoods .text-block {
		max-width: 100%; }
	.s-readyGoods .complect {
		flex-basis: 70%; }
	.s-readyGoods .area {
		font-size: 22px; }
	.s-readyGoods .price {
		font-size: 18px; }
	.s-readyGoods .round-block {
		left: 15px;
		width: 120px;
		height: 120px; }
	.s-readyGoods .item-table {
		font-size: 14px; }
	.s-readyGoods .item {
		margin-top: 120px; }
	.s-big-1 .text-block {
		font-size: 16px; }
	.button-block .button {
		font-size: 20px; }
	.s-prices {
		.complect {
			min-width: 0; }
		.table-color .table-block .complect {
			flex-basis: 70%;
			max-width: 70%; } }
	.s-review .wrap-item {
		display: block; }

	.table-color .table-block .item-table {
		font-size: 16px; }
	.s-review .img-block {
		text-align: center;
		margin: 15px auto; }
	.s-review .title {
		font-size: 20px;
		text-align: center; }
	.free-bot .section-wrap {
		font-size: 20px; }
	footer .logo a {
		font-size: 20px; }
	footer .logo-text {
		font-size: 12px; }
	.s-scheme .slick-dots li button:before {
		font-size: 16px; }
	.s-galery .slider-3 {
		text-align: center;
		img {
			display: inline-block; } }
	.quiz-item:not(.quiz-item-1) .wrap-quiz:not(.quiz-item-1), .quiz-item-1 .wrap-quiz {
		max-width: 90%; }
	.quiz-item-1 h2 {
		font-size: 35px; }
	.quiz-item-1 .descr {
		font-size: 18px; }
	.quiz-item-4 {
		.descr-item {
			font-size: 14px;
			line-height: 1.2; } }
	.quiz-item .button-block {
		flex-wrap: wrap;
		.button {
			margin-bottom: 15px; } }
	.quiz-item:not(.quiz-item-1) .wrap-quiz:not(.quiz-item-1) {
		height: auto; }
	.quiz-item {
		.item {
			margin-bottom: 15px; }
		.wrap-quiz-items {
			.wrap-quiz {
				margin: 20px 0; } } }
	.quiz-item {
		padding: 20px 0; }
	footer .section-wrap {
		flex-direction: column;
		padding: 0 10px; }
	footer .logo {
		margin: 15px 0; }
	.quiz-item-4 .wrap-items {
		display: block; }
	.thank-page {
		padding-top: 0;
		.h1-block {
			position: static; } } }



/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	/**/
	.h1, h1 {
		font-size: 25px; }
	.s-header .action-block {
		font-size: 30px; }
	.s-header .action-block-2 {
		min-width: 290px;
		max-width: 290px; }
	.s-header .action-block-2 .title {
		width: 300px;
		font-size: 20px; }
	.s-advantages .item {
		flex-basis: 50%; }
	.s-header .action-block .num-block {
		font-size: 54px; }
	.form-dark, .form-popup {
		max-width: 290px; }
	.h2.figure, h2.figure {
		font-size: 24px; }
	.button-block .button {
		padding: 12px 15px; }
	.s-review .content-block {
		font-size: 14px; }
	body {
		font-size: 14px; }
	.s-manager h2 {
		font-size: 30px; }
	.s-manager .descr {
		font-size: 22px; }
	.s-manager .wrap-items .item {
		flex-basis: 100%; }
	.table-color .table-block .complect-price {
		flex-basis: 120px; }
	.h3, h3 {
		font-size: 20px; }
	.top-header .img-logo {
		font-size: 24px; }
	.top-header .logo-text {
		font-size: 10px;
		padding: 0 5px; }
	.top-header-wrap .navigation {
		top: 53px;
		width: 100%; }
	.s-scheme .slick-next, .s-scheme .slick-prev {
		width: 40px;
		height: 40px; }
	.slick-next:before, .slick-prev:before {
		font-size: 40px; }
	.quiz-item:not(.quiz-item-1) .wrap-quiz:not(.quiz-item-1) {
		padding: 20px 10px; }
	.wrap-quiz-items .js-progress {
		width: 100%; }
	.quiz-item:not(.quiz-item-1) .button {
		white-space: normal; }
	.quiz-item-1 h2 {
		font-size: 25px; }
	.quiz-item-1 .descr {
		font-size: 16px; } }




/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }

